import MappedAddress from "../MappedAddress";
import DemoVideoUrls from "../forwarderUrls/DemoVideoUrls";
import LocalFallbackUrls from "../forwarderUrls/LocalFallbackUrls";

/**
 * Forwarder addresses used in links included in applications to play
 * in festivals.
 */

const FestivalApplications = [];

/**
 * January 2, 2024
 * Three Rivers Arts Festival 2024
 * Video link demonstrating a performance
 * Submitted to form at https://traf.trustarts.org/traf_home/artists/performing-arts
 * Application form open until January 16, 2024
 * Applicants will be notified of status in April of 2024
 * 
 * Shared URL = https://www.davidsperling.com/rd/30CA92
 */
FestivalApplications.push(new MappedAddress({
    id: '30CA92',
    urls: [DemoVideoUrls.DV_ANY_30M_GENERAL_NO_TALKING, LocalFallbackUrls.LFB_MUSIC]
}));

/**
 * January 30, 2024
 * Millvale Music Festival 2024
 * Prompt:
 *     Where can we hear your music?
 *     Please share a direct link to where we can hear your most recent music!
 *     Spotify (PREFERRED), Bandcamp, YouTube, etc. If submitting Spotify, your songs
 *     are more likely to end up in our official Festival playlist.
 * Submitted to form at https://www.millvalemusic.org/submissions
 * Submissions close on January 31st.
 * They begin inviting bands as early as February, book many in March,
 *     but are often still finalizing details into April.
 * 
 * Shared URL = https://www.davidsperling.com/rd/317901
 */
FestivalApplications.push(new MappedAddress({
    id: '317901',
    urls: [DemoVideoUrls.DV_ANY_30M_GENERAL_NO_TALKING, LocalFallbackUrls.LFB_MUSIC]
}));

/**
 * January 1, 2025
 * Millvale Music Festival 2025
 * Prompt:
 *     Where can we hear your music?
 *     Please share a direct link to where we can hear your most recent music!
 *     Spotify (PREFERRED), Bandcamp, YouTube, etc. If submitting Spotify, your songs
 *     are more likely to end up in our official Festival playlist.
 * Submitted to form at https://www.millvalemusic.org/submissions
 * Submissions close on January 1st.
 * They begin inviting bands as early as February, book many in March,
 *     but are often still revising details into April.
 * 
 * Shared URL = https://www.davidsperling.com/rd/38DDE7
 */
FestivalApplications.push(new MappedAddress({
    id: '38DDE7',
    urls: [DemoVideoUrls.DV_ANY_30M_GENERAL_NO_TALKING, LocalFallbackUrls.LFB_MUSIC]
}));

export default FestivalApplications;